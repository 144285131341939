.box[data-v-3dc1e02e] {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 9;
}
.dialog[data-v-3dc1e02e] {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
}
.dbox[data-v-3dc1e02e] {
  width: 80%;
  background: #fff;
  border-radius: 10px;
  text-align: center;
}
.dbox .title[data-v-3dc1e02e] {
  font-size: 20px;
  padding-top: 10px;
  margin-bottom: 10px;
}
.txt[data-v-3dc1e02e] {
  padding: 10px;
}
.btn[data-v-3dc1e02e] {
  padding: 10px;
  font-size: 16px;
  color: #1989fa;
  border-top: 1px solid #eee;
  border-radius: 0 0 10px 10px;
}
.btn[data-v-3dc1e02e]:active {
  background: #dcdee0;
}
@charset "UTF-8";
.content[data-v-136dd120] {
  /* 取消页面内外边距 */
  margin: 0;
  padding: 0;
  /* 100%窗口高度 */
  height: 100vh;
  /* 弹性布局 水平、垂直居中 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.sinoma-loader[data-v-136dd120] {
  display: block;
}
.sinoma-loader span[data-v-136dd120] {
  display: inline-block;
  margin-top: 10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.sinoma-loader span[data-v-136dd120]:not(:first-child) {
  margin-left: 10px;
}
.sinoma-loader span[data-v-136dd120]:nth-child(1) {
  background: #4285f4;
  -webkit-animation: move-136dd120 1s ease-in-out -0.25s infinite alternate;
          animation: move-136dd120 1s ease-in-out -0.25s infinite alternate;
}
.sinoma-loader span[data-v-136dd120]:nth-child(2) {
  background: #db4437;
  -webkit-animation: move-136dd120 1s ease-in-out -0.5s infinite alternate;
          animation: move-136dd120 1s ease-in-out -0.5s infinite alternate;
}
.sinoma-loader span[data-v-136dd120]:nth-child(3) {
  background: #f4b400;
  -webkit-animation: move-136dd120 1s ease-in-out -0.75s infinite alternate;
          animation: move-136dd120 1s ease-in-out -0.75s infinite alternate;
}
.sinoma-loader span[data-v-136dd120]:nth-child(4) {
  background: #0f9d58;
  -webkit-animation: move-136dd120 1s ease-in-out -1s infinite alternate;
          animation: move-136dd120 1s ease-in-out -1s infinite alternate;
}
@-webkit-keyframes move-136dd120 {
from {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
to {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
}
}
@keyframes move-136dd120 {
from {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
}
to {
    -webkit-transform: translateY(5px);
            transform: translateY(5px);
}
}
h1[data-v-136dd120] {
  font-family: "Montserrat", sans-serif;
  font-size: 4em;
  text-align: center;
  letter-spacing: -8px;
  margin-top: 0;
}
h1 span[data-v-136dd120] {
  padding: 0 5px;
}
h1 span[data-v-136dd120]:first-child {
  color: #4285f4;
}
h1 span[data-v-136dd120]:nth-child(2) {
  color: #db4437;
}
h1 span[data-v-136dd120]:nth-child(3) {
  color: #f4b400;
}
h1 span[data-v-136dd120]:nth-child(4) {
  color: #4285f4;
}
h1 span[data-v-136dd120]:nth-child(5) {
  color: #0f9d58;
}
h1 span[data-v-136dd120]:last-child {
  color: #db4437;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  display: inline-block;
}
@charset "UTF-8";
.content[data-v-8a3d65ba] {
  /* 取消页面内外边距 */
  margin: 0;
  padding: 0;
  /* 100%窗口高度 */
  height: 100vh;
  /* 弹性布局 水平、垂直居中 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* 渐变背景 */
  background: linear-gradient(200deg, #b5aee4, #505285);
}
.loading[data-v-8a3d65ba] {
  width: 86px;
  height: 196px;
  /* 相对定位 */
  position: relative;
  /* 弹性布局 */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* 将元素垂直显示 */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  /* 将元素靠边对齐 */
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-animation: rotating-8a3d65ba 2s linear infinite;
          animation: rotating-8a3d65ba 2s linear infinite;
}

/* 沙漏上下两个容器 */
.top[data-v-8a3d65ba],
.bottom[data-v-8a3d65ba] {
  width: 70px;
  height: 70px;
  border-style: solid;
  border-color: #dcdcdc;
  border-width: 4px 4px 12px 12px;
  border-radius: 50% 100% 50% 30%;
  position: relative;
  /* 这里把溢出的部分隐藏 */
  overflow: hidden;
}
.top[data-v-8a3d65ba] {
  /* 旋转-45度 */
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.bottom[data-v-8a3d65ba] {
  /* 旋转135度 */
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}

/* 容器里的沙 */
.top[data-v-8a3d65ba]::before,
.bottom[data-v-8a3d65ba]::before {
  content: "";
  /* 绝对定位 */
  position: absolute;
  /* inherit表示继承父元素（这里指宽高） */
  width: inherit;
  height: inherit;
  background-color: #cabbe9;
  /* 执行动画，先设置动画的参数，暂时不指定动画名称，我们在下面再来指定 */
  -webkit-animation: 2s linear infinite;
          animation: 2s linear infinite;
}
.top[data-v-8a3d65ba]::before {
  /* 通过设置圆角来改变沙的形状 */
  border-radius: 0 100% 0 0;
  /* 执行指定的动画 */
  -webkit-animation-name: drop-sand-8a3d65ba;
          animation-name: drop-sand-8a3d65ba;
}
.bottom[data-v-8a3d65ba]::before {
  /* 通过设置圆角来改变沙的形状 */
  border-radius: 0 0 0 25%;
  /* 这里我们将下面的沙移出可视范围 */
  -webkit-transform: translate(50px, -50px);
          transform: translate(50px, -50px);
  /* 执行指定的动画 */
  -webkit-animation-name: fill-sand-8a3d65ba;
          animation-name: fill-sand-8a3d65ba;
}

/* 到这里我们还少了个沙子流下来的效果 */
/* 添加流下的元素 */
.loading[data-v-8a3d65ba]::after {
  content: "";
  width: 5px;
  height: 96px;
  background-color: #cabbe9;
  /* 绝对定位 */
  position: absolute;
  top: 20px;
  /* 执行动画：动画 时长 线性的 无限次播放 */
  -webkit-animation: flow-8a3d65ba 2s linear infinite;
          animation: flow-8a3d65ba 2s linear infinite;
}

/* 接下来我们来定义动画 */
/* 落沙动画 */
@-webkit-keyframes drop-sand-8a3d65ba {
to {
    -webkit-transform: translate(-50px, 50px);
            transform: translate(-50px, 50px);
}
}
@keyframes drop-sand-8a3d65ba {
to {
    -webkit-transform: translate(-50px, 50px);
            transform: translate(-50px, 50px);
}
}
/* 填沙动画 */
@-webkit-keyframes fill-sand-8a3d65ba {
to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
}
@keyframes fill-sand-8a3d65ba {
to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}
}
/* 沙流下动画 */
@-webkit-keyframes flow-8a3d65ba {
10%, 100% {
    -webkit-transform: translateY(64px);
            transform: translateY(64px);
}
}
@keyframes flow-8a3d65ba {
10%, 100% {
    -webkit-transform: translateY(64px);
            transform: translateY(64px);
}
}
/* 最后再来一个沙漏旋转的动画 */
@-webkit-keyframes rotating-8a3d65ba {
0%, 90% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
}
@keyframes rotating-8a3d65ba {
0%, 90% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
}